import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import github from '../img/github-icon.svg'
import arLogo from '../assets/images/hy_arabic_white.svg'
import engLogo from '../assets/images/hy_english_white.svg'
import Search from './Search'

import styled from 'styled-components'
import { Context } from './Context'

const searchClose = () => {
    let searchResults = document.getElementById('search-results');
    let searchInput = document.getElementById('search-input-filter');
    searchInput.value="";
    searchResults.classList.remove('d-block');
    searchResults.classList.add('d-none')
}

const Navbar = () => (
    <Context.Consumer>
		{({ lang, toggleLanguage }) => (
            <>
            <header className="container-fluid no-left-padding no-right-padding header_s header-fix header_s3">
                <div className="container-fluid no-left-padding no-right-padding menu-block">
                    <div className="container">
                        <nav className="navbar ownavigation navbar-expand-lg">
                            {lang == 'en' ?
                                <Link className="navbar-brand" to= "/">
                                    <img src={engLogo} alt={"Halayalla Blog"} width={"100"}/>
                                </Link> :
                                <Link className="navbar-brand" to= "/ar">
                                    <img src={arLogo} alt={"Halayalla Blog"} width={"100"}/>
                                </Link>
                            }
                            {/* <Link className="navbar-brand" to={lang=='en' ? "/" : "/ar"}>
                                <img src={lang="en"?engLogo:arLogo} alt={"Halayalla Blog"} width={"150"}/>
                            </Link> */}
                            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbar4" aria-controls="navbar4" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa fa-bars"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbar4">
                                <ul className="navbar-nav justify-content-end ml-4 mr-auto">
                                    <li>
                                        <Link className="nav-link" title={"Home"}
                                            to={lang=='en' ? "/" : "/ar/"} >
                                            {lang==='en'? 'Home' : 'الرئيسية'}
                                        </Link>
                                    </li>
                                    <li>
                                        <a target="_blank" className="nav-link" title={"About"} href={lang==='en'? 'https://halayalla.com/about.php?lang=en' : 'https://halayalla.com/about.php?lang=ar'}>
                                            {lang==='en'? 'About' : 'عن هلا يلا'}
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" className="nav-link" title={"Careers"} href={lang==='en'? 'http://uxbert.com/careers/' : 'http://uxbert.com/careers/'}>
                                            {lang==='en'? 'Careers' : 'الوظائف'}
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" className="nav-link" title={"Contact Us"} href={lang==='en'? 'https://tickets.halayalla.com/en_US/contact_us' : 'https://tickets.halayalla.com/ar_SA/contact_us'}>
                                            {lang==='en'? 'Contact Us' : 'اتصل بنا'}
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" className="nav-link" title={"Download the app"} href="#" data-toggle="modal" data-target="#myModal">
                                            {lang==='en'? 'Download the app' : 'تنزيل التطبيق'}
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" className="nav-link" title={"HalaYalla for business"} href={lang==='en'? 'https://halayalla.com/halayalla-business/index.php?lang=en' : 'https://halayalla.com/halayalla-business/index.php?lang=ar'}>
                                            {lang==='en'? 'HalaYalla for business' : 'هلايلا للشركات'}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <ul className="user-info">
                                <li className="lang-btn mr-2">
                                    <span className="nav-link"  onClick={toggleLanguage}>
                                        {lang=="en" ? 'العربية' : 'English'}
                                    </span>
                                </li>
                                <li><a href="#search-box4" data-toggle="collapse" className="search collapsed" title="Search"><i className="pe-7s-search sr-ic-open" /><i className="pe-7s-close sr-ic-close" onClick={searchClose}></i></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <Search />
            </header>
            <div className="download-app modal fade in" id="myModal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-body">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="myModalLabel"></h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-6 col-sm-12 col-xs-12 hy-modal-left">
        <h1>{lang=='en' ?<div>Download The<br/>HalaYalla App Now</div> : <div>حمل ال<br/>تطبيق هلايلا الآن</div>}</h1>
                                            <ul>
                                                <li>
                                                    <a href="https://itunes.apple.com/gb/app/halayalla-find-create-sports/id1097399425?mt=8&amp;ign-mpt=uo%3D2">
                                                        <img src="https://halayalla.com/web-images/svgs/Appstore.svg" className="img-responsive" alt="" title="'" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://play.google.com/store/apps/details?id=uxbert.com.halayalla"><img alt="" title="'" src="https://halayalla.com/web-images/svgs/google-play-badge.svg" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12 text-right">
                                            <img src="https://halayalla.com/web-images/svgs/iphone-app@2x.png" className="img-responsive" alt="هلايلا" title="هلايلا'" />
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            
        )}
    </Context.Consumer>
)

export default Navbar
