import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import OwlCarousel from 'react-owl-carousel2';
import '../../node_modules/react-owl-carousel2/lib/styles.css';
import './all.scss';
import HeartIcon from '../img/favorite-heart-button.png';
import SaudiFlag from '../img/sa_flag_2.png';
import Agool from '../img/AgoolLogo@3x.svg';
import Koora from '../img/Koora.png';
import Gsa from '../img/gsalogonew@2x.png';
import Halayalla from '../assets/images/hy_english_dark.svg';
import Kafu from '../img/kafu_logo_v1.png';
import Jazeel from '../img/jazeel.png';
import Sharek from '../img/sharek1.png';
import { Context } from './Context';

import Insta1 from '../assets/images/insta1.jpg';
import Insta2 from '../assets/images/insta2.jpg';
import Insta3 from '../assets/images/insta3.jpg';
import Insta4 from '../assets/images/insta4.jpg';
import Insta5 from '../assets/images/insta5.jpg';
import Insta6 from '../assets/images/insta6.jpg';
import Insta7 from '../assets/images/insta7.jpg';
import Insta8 from '../assets/images/insta8.jpg';
import Insta9 from '../assets/images/insta9.jpg';
import Insta10 from '../assets/images/insta10.jpg';
import Insta11 from '../assets/images/insta11.jpg';
import Insta12 from '../assets/images/insta12.jpg';

const Footer = () => (
			<Context.Consumer>
				{({ lang }) => (
          // <StaticQuery
          //   query={graphql`
          //     query {
          //       allInstaNode {
          //           edges {
          //             node {
          //               id
          //               likes
          //               mediaType
          //               preview
          //               original
          //               timestamp
          //               caption
          //               localFile {
          //                 childImageSharp {
          //                   fixed(width: 1000, height: 1000) {
          //                     ...GatsbyImageSharpFixed
          //                   }
          //                 }
          //               }
          //               thumbnails {
          //                 src
          //                 config_width
          //                 config_height
          //               }
          //               dimensions {
          //                 height
          //                 width
          //               }
          //             }
          //           }
          //         }
          //     }
          //   `}

          //   render={data => (
                <footer className="container-fluid no-left-padding no-right-padding footer-main">
                    <div className="container-fluid no-left-padding no-right-padding instagram-block">
                        <OwlCarousel className="owl-carousel carousel instagram-carousel" options={{
                                    autoWidth: false,
                                    nav: false,
                                    responsive: {
                                        320: {
                                            items: 2
                                        },
                                        568: {
                                            items: 3
                                        },
                                        768: {
                                            items: 4
                                        },
                                        992: {
                                            items: 5
                                        },
                                        1200:{
                                            items: 6
                                        }
                                    }
                                }}>
                            {/* {data.allInstaNode.edges.map(edge =>  {
                                return (
                                  <>
                                    {edge.node.localFile?
                                      <div key={edge.node.id} className="insta-img">
                                        <img src={edge.node.localFile.childImageSharp.fixed.src} alt={edge.node.caption}/>
                                      </div>
                                    : ''
                                    }
                                  </>
                                );
                            })} */}
                            <div className="insta-img">
                              <img src={Insta1} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta2} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta3} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta4} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta5} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta6} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta7} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta8} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta9} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta10} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta11} width="1000px" height="1000px"/>
                            </div>
                            <div className="insta-img">
                              <img src={Insta12} width="1000px" height="1000px"/>
                            </div>
                        </OwlCarousel>
                    </div>
                    <div className="container">
                        <ul className="ftr-social">
                            <li><a href="https://www.facebook.com/halayalla/" target="_blank" title="Facebook"><i className="fa fa-facebook"></i>Facebook</a></li>
                            <li><a href="https://twitter.com/halayalla" target="_blank" title="Twitter"><i className="fa fa-twitter"></i>twitter</a></li>
                            <li><a href="https://www.instagram.com/halayalla" target="_blank" title="Instagram"><i className="fa fa-instagram"></i>Instagram</a></li>
                            <li><a href="https://www.youtube.com/channel/UCTKL7NdBf3HYPv66C3Y0-PA" target="_blank" title="Youtube"><i className="fa fa-youtube"></i>youtube</a></li>
                        </ul>
                        <div className="col-md-12">
                          <div className="fancy-title">

                            <h3>{lang=='en'? "Discover Saudi's Social Engagement Platform" : "اكتشف افضل تطبيقات المملكة للترفيه والرياضة"}</h3>
                          </div>
                          <ul className="list-inline footer-logos">
                            <li>
                              <a href="https://agool.halayalla.com/download" target="_blank" className="agoollink">
                                <div className="imgholder">
                                  <img src={Agool} alt="Agool" title="Agool" />
                                </div>
                                <p className="text-center">{lang=='en' ? 'Discover & Chat' : 'استكشف وتحدث'}</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://koora.halayalla.com/download" target="_blank" className="kooralink">
                                <div className="imgholder">
                                  <img src={Koora} alt="Koora" title="Koora" />
                                </div>
                                <p className="text-center">{lang=='en' ? 'Sports Tournaments' : 'البطولات الرياضية'}</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://gsa.live" target="_blank">
                                <div className="imgholder">
                                  <img src={Gsa} alt="GSA_Live" title="GSA_Live" />
                                </div>
                                <p className="text-center">{lang=='en' ? 'Live Streaming' : 'البث المباشر'}</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://halayalla.com/download" target="_blank">
                                <div className="imgholder">
                                  <img src={Halayalla} alt="هلايلا" title="هلايلا" />
                                </div>
                                  <p className="text-center">{lang == 'en' ? 'Sports & Entertainment' : 'مجتمعات الرياضة والترفيه'}</p>
                                  <p className="text-center">{lang == 'en' ? 'Communities' : ''}</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://kafugames.com" target="_blank">
                                <div className="imgholder">
                                  <img src={Kafu} alt="Kafu" title="Kafu" />
                                </div>
                                <p className="text-center">{lang=='en' ? 'Gaming & Esports' : 'الألعاب والرياضات الإلكترونية'}</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://jazeel.halayalla.com" target="_blank">
                                <div className="imgholder">
                                  <img src={Jazeel} alt="Influencer &amp; Viral Marketing" title="Influencer &amp; Viral Marketing" />
                                </div>
                                <p className="text-center">{lang=='en' ? 'Influencer & Viral Marketing' : 'المؤثرون والتسويق واسع النطاق'}</p>
                              </a>
                            </li>
                            <li>
                              <a target="_blank" href="https://www.sharek.sa/">
                                <div className="imgholder">
                                  <img src={Sharek} alt="Sharek" title="Sharek" />
                                </div>
                                <p className="text-center">{lang=='en' ? 'Saudi Events & Visa' : 'الفعاليات والتأشيرات السعودية'}</p>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="copyright">
                            <p>{lang=='en'? 'Made with ' : ' صُنع بـ'}
                              <span className="heart-icon"><img src={HeartIcon} alt="heart-icon" /></span>
                              {lang=='en' ? 'in KSA ' : ' في الـ'}
                              <span className="saudi-flag"><img src={SaudiFlag} alt="heart-icon" /></span>
                            </p>
                        </div>
                    </div>
                </footer>
          //   )}
          // />
        )}
      </Context.Consumer>
)

export default Footer
