import React from "react"
import { Link, graphql, useStaticQuery} from "gatsby"
import { Context } from './Context';
import * as moment from 'moment';
import 'moment/locale/ar';



const BlogIndex = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPost {
        edges {
          node {
            id
            title
            content
            better_featured_image {
              source_url
            }
            author {
              name
              slug
            }
            date(formatString: "MMMM DD, YYYY")
            slug
            wordpress_site
            tags {
              name
            }
          }
        }
      }
    }
  `)
  const allPosts = data.allWordpressPost.edges
  const emptyQuery = ""
  let title

  const [state, setState] = React.useState({
    filteredData: [],
    query: emptyQuery,
  })

  const handleInputChange = event => {
    const query = event.target.value


    const posts = data.allWordpressPost.edges || []

    const filteredData = posts.filter(post => {
      const { content, title} = post.node
      return (
        content.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) 
        
      )
    })
    let searchResults = document.getElementById('search-results');
    if(filteredData){
      if(query.length>0){
          searchResults.classList.remove('d-none');
          searchResults.classList.add('d-block')
      }else{
          searchResults.classList.remove('d-block');
          searchResults.classList.add('d-none')
      }
    }
    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  return (
    <>
      <Context.Consumer>
        {({ lang }) => (
          <div className="search-container">
            <div className="search-box collapse" id="search-box4">
              <div className="container">
                <form>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="text"
                      id="search-input-filter"
                      placeholder="Search posts..."
                      onChange={handleInputChange}
                    />
                    {/* <span className="input-group-btn">
                      <button className="btn btn-secondary" type="submit"><i className="pe-7s-search" /></button>
                    </span> */}
                  </div>
                </form>
              </div>
            </div>
            <div className="search-results d-none" id="search-results">
              <div className="container">
                {posts.filter(({node: post}) => post.wordpress_site===lang )
                  .map(({ node }) => {
                    const { content,slug, title, wordpress_site } = node
                    const  source_url  = node.better_featured_image ? node.better_featured_image.source_url : "https://placehold.it/100x80"
                    return (
                      <Link to={`${wordpress_site}/${decodeURI(slug)}`} key={slug} className="results">
                          <div className="d-flex">
                            <img src={source_url} alt="" className="search-image"/>
                            <div className="result-link">
                                <h6><span dangerouslySetInnerHTML={{ __html: title}}></span></h6>
                              <p className="post-date">{lang=='en' ? node.date : moment(node.date).format('MMMM DD, YYYY')}</p>
                            </div>
                          </div>
                        {/* <section>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: content,
                            }}
                          />
                        </section> */}
                        {/* <hr /> */}
                      </Link>
                    )
                })}
              </div>
            </div>
          </div>
        )}
      </Context.Consumer>
    </>
  )
}


export default BlogIndex