import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './all.scss';
import '../assets/css/lib.css';
import '../assets/css/rtl.css';
import '../assets/style.css';
import styled from 'styled-components';
import { addLocaleData, IntlProvider } from 'react-intl';
import localEng from '../messages/en.json';
import localAr from '../messages/ar.json';
import { Context } from './Context';
import Provider from './Context/Provider';

const TemplateWrapper = ({children}) => {
	return(
		<Provider>
			<Context.Consumer>
				{({ lang }) => (
					<IntlProvider lang={lang} messages={lang === 'en' ? localEng : localAr}>
						<Global lang={lang}>
							<Navbar />
								{children}
							<Footer />
						</Global>
					</IntlProvider>
				)}
			</Context.Consumer>
		</Provider>
	);
}

const Global = styled.div`
	font-family: 'Roboto', sans-serif;
	${({ lang }) => lang === 'ar' && `
		font-family: 'Cairo', sans-serif;    
	`}
`

export default TemplateWrapper
