import React from 'react'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { Context } from './index'


const Provider = ({ children, lang, toggleLanguage }) => (
	<Context.Provider
		value={{
			lang,
			toggleLanguage: () => toggleLanguage()
		}}
	>
		{children}
	</Context.Provider>
)


const enhance = compose(
	withState('lang', 'handleLanguage', 'en'),
	withHandlers({
		toggleLanguage: ({ lang, handleLanguage }) => () => {
			let location = window.location.href;
			let str = window.location.href.split("/");
			if (lang === 'ar') {
				str[3] = "en";
				localStorage.setItem('lang', 'en')
				window.location.assign(str[0]+"/"+str[1]+"/"+str[2]+"/");
				setTimeout(function(){
					handleLanguage('en')
				}, 400);
				
			} else {
				
				str[3] = "ar";
				localStorage.setItem('lang', 'ar')
				window.location.assign(str[0]+"/"+str[1]+"/"+str[2]+"/"+str[3]+"/");
				setTimeout(function(){
					handleLanguage('ar')
				}, 400);
			}
		}
	}),
	lifecycle({
		componentDidMount() {
			//const localLang = localStorage.getItem('lang')
			let location = window.location.href;
			let str = window.location.href.split("/");
			const localLang = str[3]
			if (localLang) {
					this.props.handleLanguage(localLang)
			} else {
				this.props.handleLanguage(navigator.language.split('-')[0])
			}
		}
	})
)

export default enhance(Provider)
